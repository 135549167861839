<template>
  <div class="app-home">
    <div class="app-home-head">
      File Prewiew
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"><i class="el-icon-arrow-left"></i></span>
      <div class="app-home-head-username" v-show="isShowHead">
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ username }}
            <i class="el-icon-arrow-down"></i>
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="confirm"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </div>
    </div>

    <div style="width:100%;height:100%;" class="vesselBox" v-loading="loading">
      <iframe ref="Iframe" style="width:100%;height:100%;" :src='defaultFileUrl' frameborder='1'>
      </iframe>
    </div>
    
  </div>
</template>

<script>
import base from "@/common/base.js";
export default {
  name: "home",
  mixins: [base],
  data() {
    return {
      loading: false, //默认不显示loading
      defaultFileUrl:'http://cdn.spec.yilark.com/11111.xlsx',
      backUrl:"/file",
      isShowHead:true,
    };
  },
  computed: {
    username() {
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      if (userInfo) {
        localStorage.setItem("loginUserNameKey", userInfo.username);
        return userInfo.username;
      } else {
        return "";
      }
    },
  },
  methods: {
    back() {
      this.$router.push({ path: this.backUrl });
    },
    iframeLoad() {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      // 兼容处理
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent("onload", () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    },
  },
  mounted() {
    //加载页面
    this.iframeLoad();

    let fileUrl = this.$route.query.fileUrl;
    if (fileUrl) {
      this.defaultFileUrl = fileUrl;
      console.log(fileUrl)
    }
    let itemcode = this.$route.query.itemcode;
    if (itemcode) {
      this.backUrl = '/sofile?itemcode='+itemcode;
      this.isShowHead = false
    }
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.vesselBox {
  height: 100%;
}
::v-deep .select-option {
  min-width: 300px;
}
.app-home {
  $size-16-9: 56vh;
  $size-16-10: 43vh;
  $size-4-3: 30vh;
  $size-3-2: 38vh;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .app-home-head {
    position: relative;
    background-color: #fff;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    .app-home-head-username {
      font-size: 14px;
      color: #333;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 10px;
      height: 66px;
      line-height: 66px;
      cursor: pointer;
    }
  }
  .app-home-preview {
    box-sizing: content-box;
    padding: 10px 120px;
    .preview-content {
      box-sizing: border-box;
      background-color: #fff;
      height: 80px;
      width: 100%;
      padding: 10px 0;
      display: flex;
      > div {
        width: 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        > div:nth-of-type(1) {
          font-size: 14px;
          color: #999;
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          font-size: 22px;
          color: #000;
        }
      }
      > div:nth-of-type(2) {
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
      }
    }
  }
  .app-home-table {
    background-color: #fff;
    margin: 10px;
    // margin-top: 0;
    .table-name {
      height: 68px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 140px;
      padding-right: 34px;
      font-size: 20px;
      color: #000;
      border-bottom: 1px solid #e8e8e8;
    }

    .ratio-16-9 {
      ::v-deep .el-table__body-wrapper {
        height: $size-16-9;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-4-3 {
      ::v-deep .el-table__body-wrapper {
        height: $size-4-3;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-16-10 {
      ::v-deep .el-table__body-wrapper {
        height: 58vh;
        overflow-y: auto;
        border: none;
      }
    }
    .ratio-3-2 {
      ::v-deep .el-table__body-wrapper {
        height: $size-3-2;
        overflow-y: auto;
        border: none;
      }
    }
    .table-list {
      overflow: hidden;
      padding-left: 60px;
      padding-right: 34px;
      .table-edit {
        display: inline-block;
        border-radius: 3px;
        padding: 2px 6px;
        cursor: pointer;
        color: #1890ff;
        &:hover {
          background-color: rgba(225, 225, 225, 0.4);
        }
      }
      .table-edit:hover {
        opacity: 0.8;
      }
      .table-pagition {
        text-align: right;
        padding: 20px 0;
        margin-top: 20px;
        .el-pagination {
          display: inline-block;
        }
      }
    }
  }
  .table-icon {
    margin-left: 10px;
    cursor: pointer;
    transition: transform 300ms;
    display: inline-block;
    &:hover {
      color: #409eff;
    }
  }
  .table-icon-rotate {
    transform: rotateZ(180deg);
  }
}
</style>